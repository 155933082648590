import React from "react"
import "./styles.scss"
const cloudinaryBaseUrl = 'https://res.cloudinary.com/dthskrjhy/'

const renderImage = (data) => {
  if (data.link_to_website) {
    return (
      <a href={data.link_to_website.url} target="_blank" rel="noreferrer">
        <img src={data.image} alt={data.name} />
      </a>
    )
  } else {
    return <img src={data.image} alt={data.name} />
  }
}

const InternationalDistributor = ({ data, ind }) => {
  return (
    <div className={`distributor col-md-6 mb-5 ${ind === 0 && 'border-right'}`}>
      {renderImage(data)}
      <div className="row">
        <div className="col">
          <h2 data-testid="addressHeader">Address</h2>
          <span data-testid="street1">{data.address_street1}</span><br />
          {data.address_street2 && <span data-testid="street2">{data.address_street2}<br /></span>}
          {data.address_city && <span data-testid="city">{data.address_city}&nbsp;</span>}
          {data.address_zip_code && <span data-testid="zip">{data.address_zip_code}<br /></span>}
          {data.address_country && <span data-testid="country">{data.address_country}<br /></span>}
          {data.link_to_website && <a data-testid="url" href={data.link_to_website.url}>{data.link_to_website.display_name}<br /></a>}
          {data.phone_number && <span data-testid="telephone">Tel: {data.phone_number}</span>}
        </div>
        <div className="col">
          <h2>Products</h2>
          {
            data.products && data.products.map((p, i) => (
              <div key={i}>
                {p.link_to_product_page ?
                  <>
                    <a href={p.link_to_product_page.url}>{p.link_to_product_page.display_name}</a><br />
                  </>
                  :
                  <>
                    <span>No Product Link</span><br />
                  </>}
                <span data-testid="dfuHeader">DFU:&nbsp;</span>
                <span>{p.dfu_english && <a className="dfu mr-1" data-testid="dfu_english" href={`${cloudinaryBaseUrl}${p.dfu_english}`}>English</a>}</span>|
                <span>{p.dfu_spanish && <a className="dfu ml-1" data-testid="dfu_spanish" href={`${cloudinaryBaseUrl}${p.dfu_spanish}`}>Spanish</a>}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default InternationalDistributor