import React from "react"
import { graphql } from "gatsby"
import _ from "lodash"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import InternationalDistributor from "../components/InternationalDistributor"
import { BASE_BREADCRUMBS } from "../constants"

const InternationalPageTemplate = ({ data }) => {
  const distributors = data && data.allInternationalDistributors.nodes
  const rowSize = 2
  if (!distributors || distributors.error)
    return (
      <Layout>
        <h1>
          We are experiencing an issue with this page. Please check back later.{" "}
        </h1>
      </Layout>
    )
  if (distributors.length === 0)
    return (
      <Layout>
        <h1>
          We currently are not associated with any International Distributors.
        </h1>
      </Layout>
    )
  return (
    <Layout
      pageTitle="Typenex Medical International Distributors"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/international", name: "International" }]}>
      <Seo
        title="International Medical Distributors | Typenex Medical"
        description="International Medical Distributors - Blood Bands | Typenex Medical"
      />
      {_.chunk(distributors, rowSize).map((chunk, i) => (
        <div className="row" key={`row${i}`}>
          {chunk.map((d, i) => (
            <InternationalDistributor data={d} ind={i} key={i} />
          ))}
        </div>
      ))}
    </Layout>
  )
}

export default InternationalPageTemplate

export const query = graphql`
  query {
    allInternationalDistributors(sort: { fields: distributor_name }) {
      nodes {
        address_city
        address_country
        address_street1
        address_street2
        address_zip_code
        id
        image
        phone_number
        distributor_name
        link_to_website {
          display_name
          url
        }
        products {
          dfu_english
          dfu_spanish
          link_to_product_page {
            display_name
            url
          }
          id
        }
      }
    }
  }
`
